
export const services = [
    {
        title: "Website Developer",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Software Engineer",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Photographer",
        icon: 'https://csgeeek.github.io/web.png',
    },
];

export const name = 'Khushbu Thakur';

export const experiences = [
    {
        'company': 'ENYO WEB SERVICES',
        'role': 'WEB Developer',
        'duration': 'june 2023 - March 2024',
        'logo': 'https://imgs.search.brave.com/lMBqjp3w_-lqRK0uj1Ato_Os4QPkqRFOwzasJtajtjU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9taXJv/Lm1lZGl1bS5jb20v/djIvMSpUSy1VbWw5/WkRuRU45WUEzZ05X/VXdRLmpwZWc',
        'points': [
            'Involved in dynamic contributions to web and mobile📱 development projects, actively influencing and improving project outcomes.',
            'Developed multiple shared components to simplify tasks for fellow developers, boosting efficiency and teamwork.',
            'Enhanced product performance through significant contributions to frontend development, optimizing essential components for improved efficiency 📈.',
            
        ],
        'url': 'https://enyowebservices.com/',
    },
    {
        'company': 'MSADIGITALSKILL',
        'role': 'Digital Marketing Intern',
        'duration': 'Jan 2023 - March 2023',
        'logo': 'https://imgs.search.brave.com/O4YUlFtNvG7YxirYznMW1Ejw5RMFcFAqCM8iqsCCzpw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tc2Fk/aWdpdGFsc2tpbGxz/LmNvbS93cC1jb250/ZW50L3VwbG9hZHMv/MjAyMy8wNS9jcm9w/cGVkLW1zYS1sb2dv/LXBuZy0xLTE2Nng3/MS5wbmc',
        'points': [
            'Created and executed digital marketing campaigns to promote a local business..',
            'Managed social media profiles, conducted keyword research, and optimized content for SEO.',
            'Worked on the application dashboard, making it more user-friendly and improving its overall functionality📱.'
        ],
        'url': 'https://msadigitalskills.com/',
    },
]



export const EMAIL_JS_SERVICE_ID = 'service_90wb9t5';
export const EMAIL_JS_TEMPLATE_ID = 'template_8l87rew';
export const EMAIL_JS_PUBLIC_KEY = 'pHPjc9RGGkF66r86N';